import * as React from 'react'
import BitsConfLogo from './../img/BitsConf-2019-Logo-Dark.png'

const Header: React.FunctionComponent = () => {
  return (
    <header>
      <div>
        <img src={BitsConfLogo} className="logo" />
      </div>
    </header>
  )
}

export default Header
