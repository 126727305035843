import * as scheduleData from './../../data/schedule.json'
import * as selectionsData from './../../data/selections.json'
import { Schedule } from './types'

const slots: any[] = Object.keys(scheduleData)
  .map((key: any) => {
    if (key !== 'default') {
      let slot: any = scheduleData[key]
      slot.dateTime = new Date()
      slot.dateTime.setMonth(slot.date.substr(3, 2))
      slot.dateTime.setYear(slot.date.substr(0, 4))
      slot.dateTime.setDate(slot.date.substr(7, 2))
      slot.dateTime.setHours(slot.time.substr(0, 2))
      slot.dateTime.setMinutes(slot.time.substr(3, 2))
      slot.dateTime.setSeconds(0)
      slot.overview = slot.overview
        ? slot.overview.replace(/(?:\r\n|\r|\n)/g, '<br>')
        : null
      return slot
    }
  })
  .filter(slot => slot !== undefined)

export const schedule: Schedule = { slots }

export const attendees: any[] = Object.keys(selectionsData)
  .map((key: any) => {
    if (key !== 'default') {
      let attendee: any = selectionsData[key]
      attendee.selections = attendee.selections
        .split(',')
        .map((no: string) => parseInt(no, 10))
      return attendee
    }
  })
  .filter(attendee => attendee !== undefined)
