import * as React from 'react'
import { Person } from './../lib/types'
import { Link } from 'react-router-dom'
interface Props {
  attendees: Person[]
}
interface State {
  name: string
}
export default class UserSelect extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = { name: '' }
  }

  handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ name: e.currentTarget.value })
  }

  renderSelectionList = () => {
    const { attendees } = this.props
    let { name } = this.state
    name = name.toLowerCase()
    const filteredAttendees = attendees.filter(person => {
      if (person.name.toLowerCase().includes(name)) {
        return true
      }
      return false
    })
    return (
      <div className="userList">
        {filteredAttendees.map(person => {
          return (
            <div key={person.email}>
              <Link to={`/${person.email}`}>{person.name}</Link>
            </div>
          )
        })}
      </div>
    )
  }

  render(): React.ReactNode {
    const { name } = this.state
    return (
      <div className="userSelectContainer">
        <div className="userSelect">
          <div>
            Start typing your name and then select your name to retrieve your
            personal schedule
          </div>
          <div>
            <input type="text" onChange={this.handleNameChange} value={name} />
          </div>
        </div>
        {name.length >= 2 && this.renderSelectionList()}
      </div>
    )
  }
}
