import * as React from 'react'
import { Schedule as ISchedule, Slot as ISlot, Person } from './../lib/types'
import Slot from './Slot'
import { Redirect } from 'react-router-dom'
interface Props {
  schedule: ISchedule
  person: Person
  clearPerson: () => void
}

interface State {
  returnToHome: boolean
}
export default class Schedule extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      returnToHome: false,
    }
  }

  clearPerson = () => {
    const { clearPerson } = this.props
    clearPerson()
    this.setState({ returnToHome: true })
  }

  render(): React.ReactNode {
    const { person, schedule } = this.props
    const { returnToHome } = this.state
    const slots = schedule.slots
      .filter(slot => person.selections.includes(slot.id))
      .sort((a, b) => (b.dateTime < a.dateTime ? 1 : -1))
    return (
      <React.Fragment>
        {returnToHome && <Redirect to="/" />}
        <h2>{person.name}</h2>
        <h3>Personalised schedule</h3>
        <div className="agenda">
          {slots.map((slot: ISlot) => {
            return <Slot key={slot.id} slot={slot} />
          })}
        </div>
        <div>
          <a onClick={this.clearPerson}> Not {person.name}?</a>
        </div>
      </React.Fragment>
    )
  }
}
