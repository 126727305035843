import * as React from 'react'
import { Person, Schedule } from './../lib/types'
import { formatTime } from '../lib/utils'
import { Link } from 'react-router-dom'

interface Props {
  attendees: Person[]
  schedule: Schedule
}
export default class Home extends React.PureComponent<Props> {
  getAttendeesForSlot = (slotId: number) => {
    const { attendees } = this.props
    return attendees
      .filter(person => person.selections.includes(slotId))
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  render(): React.ReactNode {
    const { schedule } = this.props
    return (
      <React.Fragment>
        <div>
          <h2>Session register</h2>
          {schedule.slots.map(slot => {
            if (slot.presenter) {
              return (
                <div className="slot">
                  <div className="track">
                    {slot.track ? `Room ${slot.track}` : 'Full room'}
                  </div>
                  <div className="time">
                    {`${formatTime(slot.dateTime)}`}{' '}
                    {slot.format ? ` - ${slot.format}` : ''}
                  </div>
                  <div className="title">{slot.title}</div>
                  <div className="presenter">{slot.presenter}</div>
                  <div className="overview">
                    {this.getAttendeesForSlot(slot.id).map(person => {
                      return (
                        <div key={name}>
                          <Link to={`/${person.email}`}>{person.name}</Link>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            }
            return null
          })}
        </div>
      </React.Fragment>
    )
  }
}
