import * as React from 'react'
import UserSelect from './UserSelect'
import { Person } from './../lib/types'
interface Props {
  attendees: Person[]
}
export default class Home extends React.PureComponent<Props> {
  render(): React.ReactNode {
    return (
      <React.Fragment>
        <h3>Welcome to BitsConf 2019, Lincoln</h3>
        <h4>June 13th, 2019</h4>
        <div>
          <p>We are excited to welcome you to BitsConf 2019.</p>
          <p>
            We are aiming to record all sessions and make them available on Sync
            at a later date.
          </p>
        </div>
        <UserSelect attendees={this.props.attendees} />
      </React.Fragment>
    )
  }
}
