import * as React from 'react'
import { Slot } from '../lib/types'
import { formatTime } from '../lib/utils'
interface Props {
  slot: Slot
}

const Slot: React.FunctionComponent<Props> = (props: Props) => {
  const { slot } = props
  if (!slot.presenter && !slot.track) {
    return (
      <div className="break">
        <div className="time">
          {`${formatTime(slot.dateTime)}`}{' '}
          {slot.format ? ` - ${slot.format}` : ''}
        </div>
        <div className="title">{slot.title}</div>
        {slot.overview && (
          <div
            className="overview"
            dangerouslySetInnerHTML={{ __html: slot.overview || '' }}
          />
        )}
      </div>
    )
  }
  return (
    <div className="slot">
      <div className="track">
        {slot.track ? `Room ${slot.track}` : 'Full room'}
      </div>
      <div className="time">
        {`${formatTime(slot.dateTime)}`}{' '}
        {slot.format ? ` - ${slot.format}` : ''}
      </div>
      <div className="title">{slot.title}</div>
      <div className="presenter">{slot.presenter}</div>
      {slot.overview && (
        <div
          className="overview"
          dangerouslySetInnerHTML={{ __html: slot.overview || '' }}
        />
      )}
    </div>
  )
}

export default Slot
