import * as React from 'react'
import Header from './Header'
import Schedule from './Schedule'
import Home from './Home'
import Attendees from './Attendees'
import { schedule, attendees } from '../lib/data'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import { Person } from './../lib/types'
interface AppProps {}

const USER = 'user'

const setUser = (user: string) => {
  window.localStorage.setItem(USER, user)
}

const getUser = () => {
  return window.localStorage.getItem(USER)
}

const clearUser = () => {
  window.localStorage.removeItem(USER)
}

const App: React.FunctionComponent<AppProps> = ({  }: AppProps) => {
  return (
    <div className="container" data-testid="toggle-container">
      <div>
        <Header />
        <Router>
          <Switch>
            <Route
              path="/register"
              exact={true}
              component={(props: any) => {
                return <Attendees attendees={attendees} schedule={schedule} />
              }}
            />
            <Route
              path="/:name"
              component={(props: any) => {
                const email: string = props.match.params.name
                const person: Person[] = attendees.filter(
                  (per: Person) => per.email === email
                )
                if (person.length >= 1) {
                  setUser(email)
                  return (
                    <Schedule
                      schedule={schedule}
                      person={person[0]}
                      clearPerson={clearUser}
                    />
                  )
                }
                return <h3>User not found</h3>
              }}
            />
            <Route
              path="/"
              exact={true}
              component={(props: any) => {
                const user = getUser()
                if (user) {
                  return <Redirect to={`/${user}`} />
                }
                return <Home attendees={attendees} />
              }}
            />
          </Switch>
        </Router>
      </div>
    </div>
  )
}

export default App
